import { IVenuePromo } from '@app/interfaces/shared'

import Base from './Base'

class VenuePromos extends Base {
  static async list(id: number): Promise<IVenuePromo[]> {
    const { data } = await this.get(`/locations/${id}/venue_promos`)
    return data.venuePromos
  }

  static async retrieve(id: number): Promise<IVenuePromo> {
    const { data } = await this.get(`/venue_promos/${id}`)
    return data.venuePromo
  }

  static async acknowledge(id: number): Promise<IVenuePromo> {
    const { data } = await this.patch(`/venue_promos/${id}/acknowledge`)
    return data.venuePromo
  }
}

export default VenuePromos
