import { makeAutoObservable, runInAction } from 'mobx'
import moment from 'moment'

import { Venue, VenueHealth, IVenuePromo } from '@app/interfaces/shared'
import Locations from '@app/lib/api/Locations'
import VenuePromos from '@app/lib/api/VenuePromos'
import Subscription from './Subscription'

export default class Location {
  subscription: Subscription
  id: number
  address1: string
  address2: string
  region: string
  city: string
  postcode: string
  country: string
  countryName: string
  mailingAddress1: string
  mailingAddress2: string
  mailingCity: string
  mailingRegion: string
  mailingPostcode: string
  mailingCountry: string
  genericItemsCount: number
  health: VenueHealth = {
    venueVerified: true,
    logoUploaded: true,
    menuCreated: false,
    webEmbedMenu: false,
    digitalBoard: false,
    printMenu: false,
  }
  name: string
  untappdVenueId: number
  verified: boolean
  businessType: string | null
  promos: IVenuePromo[] = []
  canCreateUntappdBeer: boolean
  timezone: string
  timezoneOffset: number
  ianaTimezone: string
  currencySymbol: string
  festivalStartOn: string | null
  festivalEndOn: string | null

  constructor(subscription: Subscription, location: Venue) {
    this.subscription = subscription

    this.set(location)

    makeAutoObservable(this, { subscription: false })
  }

  set = (params: Partial<Venue>) => {
    Object.assign(this, params)
  }

  update = async (params: Partial<Venue>): Promise<void> => {
    const { data } = await Locations.update(this.id, params)
    this.set(data)
  }

  loadVenueHealth = async (): Promise<void> => {
    try {
      const health = await Locations.health(this.id)

      this.set({ health })
    } catch {
      // noop
    }
  }

  loadVenuePromos = async (): Promise<void> => {
    try {
      const promos = await VenuePromos.list(this.id)

      this.set({ promos })
    } catch {
      // noop
    }
  }

  acknowledgePromo = async (promoId: number) => {
    const promoIndex = this.promos.findIndex((promo) => promo.id === promoId)

    runInAction(() => {
      this.promos[promoIndex].userAcknowledgedScheduledAt =
        moment().toISOString()
    })

    const promo = await VenuePromos.acknowledge(promoId)

    runInAction(() => {
      this.promos[promoIndex] = promo
    })
  }

  get isVerified() {
    return this.verified
  }

  get canCreateBeer() {
    return this.canCreateUntappdBeer
  }
}
